body {
  font-family: "Poppins", sans-serif !important;
}
.App {
  text-align: center;
}
.background_image {
  background-image: url("../../evpe-website/src/assets/images/partner-bg.png");
}
.pBtn {
  background-color: black;
  color: #ffffff;
  border-radius: 15px;
}
.pageHeading {
  font-family: "Poppins";
  font-weight: bold;
  color: #000000 !important;
}
.pageDescription {
  color: #1e1e1e;
  font-size: 17px;
}
.cardHeading {
  /* font-weight:lighter; */
  font-family: "Poppins";
  font-size: 20px;
}
.sectionHeading {
  font-size: 27px;
  font-family: Poppins, sans-serif;
}
.sectionContent {
  font-family: Poppins, sans-serif;
  color: #1e1e1e;
  font-size: 18;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
/* .Btn{
  width:200px;
  height:45px;
  text-align: center;
  background-color: black;
  border-radius: 17px;
  color: #ffffff;
} */
.save_cal_btn {
  width: 300px;
  height: 45px;
  text-align: center;
  background-color: black;
  border-radius: 17px;
  color: #ffffff;
}
.button_style {
  background: #000;
  border-radius: 19.538px;
  justify-content: center;
  align-items: center;
  gap: 10.226px;
  padding: 10.815px 30px;
  display: inline-flex;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  margin-top: 30px;
  box-shadow: 5px 10px 8px 0px rgba(0, 0, 0, 0.3);
  border: none;
}
.back_button_style{
  background: #000;
  border-radius: 19.538px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10.815px 25px;
  display: inline-flex;
  color: #fff;
  
  font-size: 20px;
  font-style: normal;
  margin-top: 30px;
  
}
.notify_button_style{
  background: #000;
  border-radius: 19.538px;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  padding: 7.815px 25px;
  display: inline-flex;
  color: #fff;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal; 
}
.profile {
  background-color: black;
  color: #ffffff;
  border-radius: 50%;
  height: 23px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.profile-em-icon {
  background-color: black;
  color: #ffffff;
  border-radius: 50%;
  height: 23px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  padding: 12px;
  margin-right:7px
}
.error-message {
  color: red;
  font-size: 13px;
  margin-left: 9px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.carousel .control-dots .dot {
  background: #212529 !important;
}
.form-control.input-height.rounded-4:focus {
  border-color: #dee2e6 !important;
  box-shadow: none !important;
  outline: none;
}
.form-select.input-height.rounded-4:focus {
  border-color: #dee2e6 !important;
  box-shadow: none !important;
  outline: none;
}


/* @media only screen and (max-width: 768px) {
  input::placeholder,
  select::placeholder,
  textarea::placeholder {
      opacity: 0 !important; 
  }

  input:-ms-input-placeholder, 
  select:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
      opacity: 0 !important;
  }

  input::-ms-input-placeholder, 
  select::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
      opacity: 0 !important;
  }
} */
@media only screen and (max-width: 768px) {
  /* Hide placeholder for all inputs, selects, and textareas */
  input::placeholder,
  select::placeholder,
  textarea::placeholder {
    opacity: 0 !important;
  }

  input:-ms-input-placeholder, 
  select:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    opacity: 0 !important;
  }

  input::-ms-input-placeholder, 
  select::-ms-input-placeholder,
  textarea::-ms-input-placeholder {
    opacity: 0 !important;
  }

  /* Show placeholder for the specific input with ID 'email-input' */
  .comingsoon-email::placeholder {
    opacity: 1 !important;
  }

  .comingsoon-email::-ms-input-placeholder {
    opacity: 1 !important;
  }

  .comingsoon-email::-ms-input-placeholder {
    opacity: 1 !important;
  }
}



/* Adjust container to be responsive */
.container-fluid {
  width: 100%;
  max-width: 1800px;
  padding: 0 1rem;
}
